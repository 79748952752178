import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async';
import emailjs from "emailjs-com";
import './Elabs.css';
import elsmall from "../assets/small_expertlancing.png";
import vmbgmock from "../assets/vm-mock-bg.png";
import vmmock from "../assets/vm-mock.png";
import svbg from "../assets/sv-mock-bg.png";
import svmock from "../assets/sv-mock.png";
import expertBg from "../assets/expertS.png";
import expertMock from "../assets/expertSmock.png";
import lineElement from "../assets/lineElement.png";
import Fline from "../assets/firstLine.png";
import Sline from "../assets/secondLine.png";
import Tline from "../assets/thirdLine.png";
import leftQuote from "../assets/leftLinequote.png";
import rightQuote from "../assets/rightLinequote.png";
import { Link } from 'react-router-dom';


const Elabs = () => {
  // State to track the currently active tab
  const [activeNav, setActiveNav] = useState('');
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    tools: [],
    question: '',
    date: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare data for EmailJS
    const emailData = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      email: formData.email,
      tools: formData.tools.join(', '), // Join the selected tools
      question: formData.question,
      date: formData.date,
    };

    // Use EmailJS to send the email
    emailjs.send('service_6inkvuc', 'template_gvuxdwn', emailData, 'FLc-uym9Z5SipTLOf')
      .then((result) => {
        //console.log('Email successfully sent:', result.text);
        alert('Your query has been submitted!');
        setFormData({
          firstname: '',
          lastname: '',
          email: '',
          tools: [],
          question: '',
          date: '',
        }); // Clear form data
      }, (error) => {
        // console.log('Email sending error:', error.text);
        alert('There was an error sending your application. Please try again.');
      });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevState) => ({
        ...prevState,
        tools: checked
          ? [...prevState.tools, value]
          : prevState.tools.filter((tool) => tool !== value),
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // Function to handle navigation item click
  const handleNavClick = (id) => {
    setActiveNav(id);
  };
  return (
    <section className='elabs-bg flex flex-col items-center'>
      <Helmet>
        <title>E-Labs</title>
        <meta name='description' content='Reach beyond your expectations with our Expert solutions created by pushing the boundaries of AI.' />
      </Helmet>
      <div className="landingScreen">
        <div className="content-container">
          <h1 className="moveUp">Welcome to E-labs</h1>
          <h2 className="fadeInUp">Harness<br />the power of AI</h2>
        </div>
        <div className="stars">
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
        </div>
      </div>
      <div className='Elabs_innernav sticky top-14 z-50 w-full shadow-sm'>
        <ul class='in-nav-active flex items-center justify-center gap-28 w-full py-5 text-white xs:max-sm:gap-8 xs:max-sm:text-sm'>
          <a href="#visionmap"><li className={activeNav === 'visionmap' ? 'active-inner' : ''} onClick={() => handleNavClick('visionmap')}>VisionMap</li></a>
          <a href="#stanvid"><li className={activeNav === 'stanvid' ? 'active-inner' : ''} onClick={() => handleNavClick('stanvid')}>StanVid</li></a>
          <a href="#expertshare"><li className={activeNav === 'expertshare' ? 'active-inner' : ''} onClick={() => handleNavClick('expertshare')}>ExpertShare</li></a>
        </ul>
      </div>
      <div className='info-stats h-fit w-10/12 relative'>
        <div className='flex items-start justify-center gap-10 p-10 xs:max-sm:block xs:max-sm:p-2 xs:max-sm:gap-16'>
          <div className='w-full'>
            <h2 className='text-3xl font-semibold text-white xs:max-sm:text-2xl'>Redifining<br />Innovation</h2>
            <p className='text-xl py-5 text-white xs:max-sm:text-lg'>With AI-Powered Insights</p>
            <p className='text-base text-white xs:max-sm:text-[12px]'>
              We believe in pushing the boundaries of AI technology,
              ensuring that our solutions not only meet but exceed your
              expectations. Discover unparalleled accuracy through our
              algorithms, powered by a massive, proprietary dataset
              meticulously curated to handle any query with precision,
              making every action a step towards excellence.
            </p>
          </div>
          <div className='xs:max-sm:hidden'></div>
          <div className='xs:max-sm:hidden'></div>
          <div className='w-full h-6/12 grid grid-cols-2 auto-rows-auto gap-10 pt-28 xs:max-sm:pt-10'>
            <div className='bg-white/50 backdrop-blur-sm text-white text-center flex flex-col items-center justify-around h-52 w-52 object-cover xs:max-sm:h-28 xs:max-sm:w-28'>
              <p className='text-xs'>With a database of</p>
              <h2 className='text-3xl font-semibold xs:max-sm:text-xl'>186M+</h2>
              <p className='text-xs'>patents from various sources</p>
            </div>
            <div className='bg-white/50 backdrop-blur-sm text-white text-center flex flex-col items-center justify-around h-52 w-52 object-cover xs:max-sm:h-28 xs:max-sm:w-28'>
              <p className='text-xs'>Presenting</p>
              <h2 className='text-3xl font-semibold xs:max-sm:text-xl'>300+</h2>
              <p className='text-xs'>types of charts for analysis</p>
            </div>
            <div className='bg-white/50 backdrop-blur-sm text-white text-center flex flex-col items-center justify-around h-52 w-52 object-cover xs:max-sm:h-28 xs:max-sm:w-28'>
              <p className='text-xs'>More than</p>
              <h2 className='text-3xl font-semibold xs:max-sm:text-xl'>90%</h2>
              <p className='text-xs'>accuracy while searching the set</p>
            </div>
            <div className='bg-white/50 backdrop-blur-sm text-white text-center flex flex-col items-center justify-around h-52 w-52 object-cover xs:max-sm:h-28 xs:max-sm:w-28'>
              <p className='text-xs'>Developed</p>
              <h2 className='text-3xl font-semibold xs:max-sm:text-xl'>10+</h2>
              <p className='text-xs'>projects incorporated with AI</p>
            </div>
          </div>
        </div>
      </div>
      <div className='projects visonM flex items-center justify-start py-10 px-20 xs:max-sm:px-10 xs:max-sm:flex-col' id='visionmap'>
{/* heading responsiveness start */}
        <div className='hidden xs:max-sm:block'>
          <img className='w-20' src={lineElement} alt='' />
          <p className='text-white text-xl tracking-widest text-left'>VisionMap</p>
          <h2 className='text-3xl font-semibold text-white text-left'>INNOVATION<br />BEYOND<br />IMAGINATION</h2>
        </div>
{/* heading responsiveness end */}

{/* --image div-- */}

        <div className='w-6/12 flex flex-col items-center relative xs:max-sm:w-full'>
          <img className='w-9/12 absolute top-14' src={vmmock} alt='' />
          <img className='w-9/12' src={vmbgmock} alt='' />
        </div>

{/* --image div-- */}

{/* para responsiveness start */}
        <div className='hidden xs:max-sm:block'>
          <p className='text-sm w-full text-white text-justify'>
            Harness the power of data to transform complex information into actionable insights.
            Step onto the Next Level with single platform multi-feature interface like never before.
            An interface that is made to maximize ease with an intelligence of ineffable efficiency.
          </p>
          <div className='flex items-center justify-start relative py-5'>
            <Link to={'/elabs/visionmap'} className='flex items-center justify-center explr-btn font-semibold bg-white h-10 w-28 pr-5 text-center hover:text-red-800'>Explore</Link>
            <div className='absolute left-16 explr-chevron h-10 w-10 bg-white'></div>
          </div>
        </div>
{/* para responsiveness end */}

        <div className='w-6/12 flex flex-col items-end self-start pr-20 xs:max-sm:hidden'>
          <img className='w-20' src={lineElement} alt='' />
          <p className='text-white text-xl tracking-widest text-right'>VisionMap</p>
          <h2 className='text-3xl font-semibold text-white text-right'>INNOVATION<br />BEYOND<br />IMAGINATION</h2>
          <p className='text-sm w-10/12 text-white text-justify'>
            Harness the power of data to transform complex information into actionable insights.
            Step onto the Next Level with single platform multi-feature interface like never before.
            An interface that is made to maximize ease with an intelligence of ineffable efficiency.
          </p>
          <div className='flex items-center justify-center relative py-5'>
            <Link to={'/elabs/visionmap'} className='flex items-center justify-center explr-btn font-semibold bg-white h-10 w-28 pr-5 text-center hover:text-red-800'>Explore</Link>
            <div className='absolute right-2 explr-chevron h-10 w-10 bg-white'></div>
          </div>
        </div>
      </div>


      <div className='projects stanV flex items-center justify-start py-10 px-20 xs:max-sm:px-10 xs:max-sm:flex-col' id='stanvid'>
        <div className= 'w-6/12 flex flex-col items-start self-start pl-20 xs:max-sm:hidden'>
          <p className='text-white text-xl tracking-widest text-left'>StanVid</p>
          <h2 className='text-3xl font-semibold text-white text-left'>GETTING AHEAD<br />OF THE<br />TECHNOLOGY</h2>
          <p className='text-sm w-10/12 text-white text-justify'>
            All the knowledge of Standards used across the globe.
            A singular entity with immense capabilities.
            Searching, saving, sharing and analyzing data all at one place.
          </p>
          <img className='py-10 w-20' src={lineElement} alt='' />
          <div className='flex items-center justify-center relative'>
            <Link to={'/contact'} className='flex items-center justify-center explr-btn font-semibold bg-white h-10 w-28 pr-5 text-center hover:text-red-800'>Explore</Link>
            <div className='absolute right-2 explr-chevron h-10 w-10 bg-white'></div>
          </div>
        </div>


{/* heading responsiveness start */}
        <div className='hidden flex-col items-end justify-center xs:max-sm:flex'>
          <img className='w-20' src={lineElement} alt='' />
          <p className='text-white text-xl tracking-widest text-right'>StanVid</p>
          <h2 className='text-3xl font-semibold text-white text-right'>GETTING AHEAD<br />OF THE<br />TECHNOLOGY</h2>
        </div>
{/* heading responsiveness end */}

{/* --image div-- */}
        <div className='w-6/12 flex flex-col items-center relative xs:max-sm:w-full'>
          <img className='w-9/12 absolute top-14' src={svmock} alt='' />
          <img className='w-9/12' src={svbg} alt='' />
        </div>
{/* --image div-- */}

{/* para responsiveness start */}
        <div className='hidden xs:max-sm:block'>
          <p className='text-sm w-full text-white text-justify'>
            All the knowledge of Standards used across the globe.
            A singular entity with immense capabilities.
            Searching, saving, sharing and analyzing data all at one place.
          </p>
          <div className='flex items-center justify-end pt-5 relative'>
            <Link to={'/contact'} className='flex items-center justify-center explr-btn font-semibold bg-white h-10 w-28 pr-5 text-center hover:text-red-800'>Explore</Link>
            <div className='absolute right-2 explr-chevron h-10 w-10 bg-white'></div>
          </div>
        </div>
{/* para responsiveness end */}
      </div>


      <div className='projects expertS flex items-center justify-start py-10 px-20 xs:max-sm:px-10 xs:max-sm:flex-col' id='expertshare'>
{/* heading responsiveness start */}
        <div className='hidden flex-col items-start justify-center w-full xs:max-sm:flex'>
          <img className='w-20' src={lineElement} alt='' />
          <p className='text-white text-xl tracking-widest text-left'>ExpertShare</p>
          <h2 className='text-3xl font-semibold text-white text-left'>SWIFT.<br />SECURE.<br />RESILIENT.</h2>
        </div>
{/* heading responsiveness end */}

{/* --image div-- */}
        <div className='w-6/12 flex flex-col items-center relative xs:max-sm:w-full'>
          <img className='w-9/12 absolute top-14' src={expertMock} alt='' />
          <img className='w-9/12' src={expertBg} alt='' />
        </div>
{/* --image div-- */}

{/* para responsiveness start */}
        <div className='hidden xs:max-sm:block'>
          <p className='text-sm w-full text-white text-justify'>
            Experience a new standard in collaborative success!
            Your cutting-edge project management solution. Easily
            upload files and folders while tracking project milestones
            and deliverables in real-time. Stay connected with the team
            seamlessly, ensuring clarity and efficiency from start to finish.
          </p>
          <div className='flex items-center justify-start relative py-5'>
            <Link to={'/contact'} className='flex items-center justify-center explr-btn font-semibold bg-white h-10 w-28 pr-5 text-center hover:text-red-800'>Explore</Link>
            <div className='absolute left-16 explr-chevron h-10 w-10 bg-white'></div>
          </div>
        </div>
{/* para responsiveness end */}

        <div className='xs:max-sm:hidden w-6/12 flex flex-col items-end self-start pr-20'>
          <img className='w-20' src={lineElement} alt='' />
          <p className='text-white text-xl tracking-widest text-right'>ExpertShare</p>
          <h2 className='text-3xl font-semibold text-white text-right'>SWIFT.<br />SECURE.<br />RESILIENT.</h2>
          <p className='text-sm w-10/12 text-white text-justify'>
            Experience a new standard in collaborative success!
            Your cutting-edge project management solution. Easily
            upload files and folders while tracking project milestones
            and deliverables in real-time. Stay connected with the team
            seamlessly, ensuring clarity and efficiency from start to finish.
          </p>
          <div className='flex items-center justify-center relative py-5'>
            <Link to={'/contact'} className='flex items-center justify-center explr-btn font-semibold bg-white h-10 w-28 pr-5 text-center hover:text-red-800'>Explore</Link>
            <div className='absolute right-2 explr-chevron h-10 w-10 bg-white'></div>
          </div>
        </div>
      </div>


      <div className='factors w-10/12 text-white p-10 xs:max-sm:w-full'>
        <h2 className='text-3xl font-semibold'>LOOK FORWARD TO</h2>
        <div className='flex items-center justify-start gap-10 pt-10 xs:max-sm:gap-5 xs:max-sm:flex-col'>
          <div className='pb-48 flex flex-col items-end gap-5 xs:max-sm:items-start xs:max-sm:pb-0'>
            <h3 className='text-2xl'>Efficiency & Accuracy</h3>
            <p className='text-xs text-right xs:max-sm:text-left'>
              Utilizing the power of natural language processing and machine learning, our AI model quickly and accurately
              scan vast amounts of data pinpointing relevant information faster and more accurately than humans.
            </p>
            <img className='w-20' src={Fline} alt='' />
          </div>
          <div className='py-20 flex flex-col items-end gap-5 xs:max-sm:py-10'>
            <h3 className='text-2xl xs:max-sm:text-right'>Comprehensive Analysis</h3>
            <p className='text-xs text-right'>
              With the ability to analyze large datasets, our AI algorithms can thrive in finding white space
              opportunities allowing us to gain a deeper understanding of the product landscape, encouraging more
              robust and effective strategies.
            </p>
            <img className='w-20' src={Sline} alt='' />
          </div>
          <div className='pt-48 flex flex-col items-end gap-5 xs:max-sm:items-start xs:max-sm:pt-0'>
            <h3 className='text-2xl'>Competitive Advantage</h3>
            <p className='text-xs text-right xs:max-sm:text-left'>
              By leveraging AI to stay ahead of industry trends, we can gain a better understanding of the competitors
              and make informed decisions ultimately leading to a stronger position in the market.
            </p>
            <img className='w-20' src={Tline} alt='' />
          </div>
        </div>
      </div>


      <div className='w-10/12 flex items-start justify-center py-20'>
        <img className='w-20 xs:max-sm:w-10' src={leftQuote} alt='' />
        <p className='text-white text-xl py-16 w-8/12 xs:max-sm:text-xs xs:max-sm:w-full xs:max-sm:text-center'>
          The rise of AI marks not the fall of human ingenuity, but its ascension to new heights, where the synergy of
          human intelligence and artificial intelligence creates an era of unprecedented possibilities.
        </p>
        <img className='w-20 self-end xs:max-sm:w-10' src={rightQuote} alt='' />
      </div>


      <div className="demo-cont">
        <p className="demo">REQUEST DEMO</p>
        <h3>
          Schedule a free Demo with us
        </h3>
        <p className="demo">How does our AI help you create better than before? Book a free demo with us to get your
          questions answered.</p>
        <div className="book-form-cont">
          <div className="quote-cont">
            <div className="upper-line">
              <div className='flex items-center gap-5'>
                <img className='w-10' src={elsmall} alt='' />
                <div>
                  <span className="namespan">Expertlancing</span>
                  <span className="positionspan">Research Services</span>
                </div>
              </div>
              <hr />
            </div>
            <p className="bottomline">At our Labs, we are dedicated to developing tools and services that not only enhance productivity but also elevate the overall quality of work, empowering our team to achieve excellence with ease and efficiency.</p>
          </div>
          <form action="/labs" method="post" className="demo-form" onSubmit={handleSubmit}>
            <div className="name-cont">
              <div className="fname">
                <label for="firstname">First Name</label>
                <input
                  type='text'
                  name='firstname'
                  value={formData.firstname}
                  onChange={handleChange}
                  placeholder='First Name'
                  className='p-2 border border-gray-300 rounded'
                  required
                />
              </div>
              <div className="lname">
                <label for="lastname">Last Name</label>
                <input
                  type='text'
                  name='lastname'
                  value={formData.lastname}
                  onChange={handleChange}
                  placeholder='Last Name'
                  className='p-2 border border-gray-300 rounded'
                  required
                />              </div>
            </div>
            <div className="email-cont">
              <label for="mail">Work Email</label>
              <input
                type='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                placeholder='Email'
                className='w-full p-2 border border-gray-300 rounded'
                required
              />            </div>
            <div className="project-cont">
              <span>Which tool do you want to explore?</span>
              <div className="projectss">
                <div className="stan-pro">
                  <input
                    type='checkbox'
                    name='tools'
                    value='StanVid'
                    checked={formData.tools.includes('StanVid')}
                    onChange={handleChange}
                  />                  <label for="stans">StanVid</label>
                </div>
                <div className="dash-pro">
                  <input
                    type='checkbox'
                    name='tools'
                    value='VisionMap'
                    checked={formData.tools.includes('VisionMap')}
                    onChange={handleChange}
                  />                  <label for="dashes">VisionMap</label>
                </div>
                <div className="expert-pro">
                  <input
                    type='checkbox'
                    name='tools'
                    value='ExpertShare'
                    checked={formData.tools.includes('ExpertShare')}
                    onChange={handleChange}
                  />
                  <label for="expert">ExpertShare</label>
                </div>
              </div>
            </div>
            <div className="question-cont">
              <label for="question">What do you want to ask?</label>
              <textarea
                name='question'
                value={formData.question}
                onChange={handleChange}
                placeholder='Your Question'
                className='w-full p-2 border border-gray-300 rounded'
                rows='4'
              />            </div>
            <div className="date-cont">
              <label for="date">When do you wish to schedule the demo? </label>
              <input
                type='date'
                name='date'
                value={formData.date}
                onChange={handleChange}
                className='w-full p-2 border border-gray-300 rounded'
                required
              />            </div>
            <button type='submit'
              className='w-full bg-blue-500 text-white p-2 rounded'>Get Free Demo</button>
          </form>
        </div>
      </div>
    </section>
  )
}
export default Elabs