import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useMediaQuery from '../components/useMediaQuery';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { Brands } from '../components/brands';
import "./sep.css";

export const Licensing = () => {
  const [activeTab, setActiveTab] = useState('tab1');
  const [filteredInsights, setFilteredInsights] = useState([]);
  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    const storedData = localStorage.getItem('filteredExcelData');
    if (storedData) {
      setFilteredInsights(JSON.parse(storedData));
    }
  }, []);

  const handleClick = () => {
    document.getElementById('more-txt').style.height = 'fit-content';
    document.getElementById('more-btn').style.display = 'none';
  }
  const handleClose = () => {
    document.getElementById('more-txt').style.height = '0px';
    document.getElementById('more-btn').style.display = 'block';
  }

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "50px",
    slidesToShow: isSmallScreen ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    cssEase: "linear"
  };

  function ExcelDateToJSDate(serial) {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400 * 1000; // Convert to milliseconds
    const date_info = new Date(utc_value);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;
    let total_seconds = Math.floor(86400 * fractional_day);
    const seconds = total_seconds % 60;
    total_seconds -= seconds;
    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    // Create a new date with valid values
    const year = date_info.getFullYear();
    const month = date_info.getMonth();
    const day = date_info.getDate();

    // Ensure the values are valid
    if (isNaN(year) || isNaN(month) || isNaN(day) ||
      isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      return null; // or handle error as appropriate
    }

    return new Date(year, month, day, hours, minutes, seconds);

  }

  const insightsSlider = filteredInsights.map((insight, index) => (
    <Link key={index} className="cardcont" to={`/${insight.title.replaceAll(' ', '-')}`}>
      <div className='slidecard'>
        <img src={`/insights_img/${insight.main_image_all}`} alt={insight.title} />
        <div className="slidecard-txt h-full">
          <div>
            <span className="slidecard-tag">{insight.insight_type}</span>&nbsp;&nbsp;&nbsp;
            <span className="slidecard-tag">
              {format(new Date(ExcelDateToJSDate(insight.Submitted_on).toDateString()), 'EEEE, MMM, d, yyyy')}
            </span>
          </div>
          <h3>{insight.title}</h3>
          <p className="truncate" dangerouslySetInnerHTML={{ __html: insight.Abstract }}></p>
        </div>
      </div>
    </Link>
  ));

  const tabContents = {
    tab1: (
        <div className='section-details'>
            <div className='flex items-start justify-between gap-10 mb-10'>
              <h2 className='w-1/2 font-customFont3 font-semibold text-3xl mb-10'>From <span className='text-red-700 !font-customFont3 font-semibold text-3xl'>Fortune 500s to cutting-edge innovators</span>—our licensing support turns IP into revenue streams.</h2>
              <div className='flex flex-col items-start gap-5 w-1/2'>
                <p>
                At ExpertLancing, we <span className='font-customFont2 text-red-700'>empower businesses with the intelligence, research, and insights</span> needed to make informed patent licensing decisions. Our expertise in patent analytics, market intelligence, and competitive landscape evaluation helps clients identify the right licensing opportunities, mitigate risks, and maximize the value of their IP portfolios.
                In an environment where patent licensing decisions can shape market positioning, revenue streams, and competitive advantages, we ensure <span className='font-customFont2 text-red-700'>our clients are backed by data, not assumptions.</span>
                </p>
              </div>
            </div>
            <div className='mb-16'>
              <p className="!font-semibold !text-xl mb-5">Your <span className='font-customFont2 text-red-700'>all-in-one stop</span> for complete IP licensing solutions</p>
              <img src={`/solution_img/licensing_process.png`} alt=''/>
            </div>
            <p className="!font-semibold !text-xl">Strategic & <span className='font-customFont2 text-red-700'>Intelligence-Driven</span> Licensing Research</p>
            <p className="mb-10">Licensing success begins with the right information. <span className='font-customFont2 text-red-700'>Our research-driven approach</span> ensures that every decision is backed by strong data, technical precision, and industry foresight.</p>
            <div>
              <div className="flex items-start justify-center py-10">
                  <div className="w-11/12 relative flex flex-col items-start justify-center gap-10 xs:max-sm:w-full">
                      <h2 className="absolute z-30 -top-10 -left-5 text-9xl font-bold text-red-600 opacity-20">1</h2>
                      <div className="w-9/12 flex flex-col items-start justify-center text-justify gap-5 pl-10">
                          <h2 className="font-customFont2 text-xl font-semibold">Patent Portfolio Evaluation & Licensing Readiness</h2>
                          <p className="">
                          Before entering any licensing discussions, understanding the <span className='font-customFont2 text-red-700'>strength, relevance, and monetization potential</span> of a patent portfolio is crucial. We conduct:
                          </p>
                          <ul>
                            <li className='list-custom-bullet'>Patent-to-market mapping to identify industries and companies utilizing similar technologies.</li>
                            <li className='list-custom-bullet'>Portfolio segmentation to classify patents into high-value, licensing-ready, or defensive-use categories.</li>
                            <li className='list-custom-bullet'>Evidence of Use (EoU) research to assess potential infringement scenarios and strengthen licensing claims.</li>
                          </ul>
                          <p className="">
                          Our structured analysis helps clients make <span className='font-customFont2 text-red-700'>fact-based decisions</span> on which patents to license, enforce, or leverage for cross-licensing strategies.
                          </p>
                      </div>
                  </div>
              </div>
              <div className="flex items-start justify-center py-10">
                  <div className="w-11/12 relative flex flex-col items-end justify-center gap-10 xs:max-sm:w-full">
                      <h2 className="absolute z-30 -top-10 -right-5 text-9xl font-bold text-red-600 opacity-20">2</h2>
                      <div className="w-9/12 flex flex-col items-end justify-center text-justify gap-5 pr-10">
                          <h2 className="font-customFont2 text-xl font-semibold">Identifying Potential Licensing Opportunities</h2>
                          <p className="">
                          Not all patents are suitable for licensing, and not all companies are suitable licensees. We conduct <span className='font-customFont2 text-red-700'>in-depth market and industry research</span> to:
                          </p>
                          <ul>
                            <li className='list-custom-bullet'>Identify companies that may need access to your patented technology.</li>
                            <li className='list-custom-bullet'>Analyze competitors' IP strategies to uncover potential licensing trends.</li>
                            <li className='list-custom-bullet'>Assess market adoption rates for patented technologies to determine real-world applicability.</li>
                          </ul>
                          <p className="">
                          This data-driven approach eliminates guesswork, ensuring that licensing discussions are <span className='font-customFont2 text-red-700'>focused, targeted, and aligned with market realities.</span>
                          </p>
                      </div>
                  </div>
              </div>
              <div className="flex items-start justify-center py-10">
                  <div className="w-11/12 relative flex flex-col items-start justify-center gap-10 xs:max-sm:w-full">
                      <h2 className="absolute z-30 -top-10 -left-5 text-9xl font-bold text-red-600 opacity-20">3</h2>
                      <div className="w-9/12 flex flex-col items-start justify-center gap-5 pl-10">
                          <h2 className="font-customFont2 text-xl font-semibold">SEP & FRAND Licensing Intelligence</h2>
                          <p className="">
                          For companies involved in <span className='font-customFont2 text-red-700'>Standard-Essential Patent (SEP) licensing</span>, we provide specialized research to:
                          </p>
                          <ul>
                            <li className='list-custom-bullet'>Analyze SEP declarations and essentiality claims for compliance and strength.</li>
                            <li className='list-custom-bullet'>Assess industry-wide FRAND licensing benchmarks to ensure fairness in licensing rates.</li>
                            <li className='list-custom-bullet'>Identify non-infringing alternatives to mitigate dependency on SEP licensing.</li>
                          </ul>
                          <p className="">
                          Our expertise helps both <span className='font-customFont2 text-red-700'>SEP owners and licensees</span> navigate the complex world of standards-based licensing with confidence.
                          </p>
                      </div>
                  </div>
              </div>
              <div className="flex items-start justify-center py-10">
                  <div className="w-11/12 relative flex flex-col items-end justify-center gap-10 xs:max-sm:w-full">
                      <h2 className="absolute z-30 -top-10 -right-5 text-9xl font-bold text-red-600 opacity-20">4</h2>
                      <div className="w-9/12 flex flex-col items-end justify-center text-justify gap-5 pr-10">
                          <h2 className="font-customFont2 text-xl font-semibold">Litigation & Risk Assessment in Licensing</h2>
                          <p className="">
                          Patent licensing is not just about monetization—it also involves legal risks, compliance challenges, and potential disputes. Our research supports legal teams with:
                          </p>
                          <ul>
                            <li className='list-custom-bullet'>Infringement risk assessment to evaluate whether licensing is a necessary step for risk mitigation.</li>
                            <li className='list-custom-bullet'>Pre-litigation intelligence to determine the likelihood of licensing disputes escalating into legal battles.</li>
                            <li className='list-custom-bullet'>Prior art and invalidity research to strengthen defensive strategies in licensing negotiations.</li>
                          </ul>
                          <p className="">
                          By <span className='font-customFont2 text-red-700'>identifying red flags early and equipping legal teams with data-driven insights</span>, we help clients navigate licensing engagements strategically.
                          </p>
                      </div>
                  </div>
              </div>
            </div>
        </div>
    ),
    tab2: (
        <div className='section-details'>
            <div className='flex items-start justify-between gap-10 mb-10'>
              <h2 className='w-1/2 font-customFont3 font-semibold text-3xl mb-10'>Securing technology rights, protecting IP assets, and maximizing revenue—<span className='!font-customFont3 font-semibold text-3xl text-red-700'>our licensing strategies do it all.</span></h2>
              <p className='w-1/2'>Licensing is no longer just about monetization—it’s about <span className='font-customFont2 text-red-700'>strategic positioning, risk mitigation, and future-proofing business growth.</span> At ExpertLancing, we partner with corporate IP teams, R&D-driven companies, law firms, and technology leaders who need actionable intelligence to make informed licensing decisions.
              <br/><br/>We support those who seek <span className='font-customFont2 text-red-700'>clarity in complex IP landscapes</span>—whether they need to identify licensing opportunities, evaluate SEP compliance, mitigate infringement risks, or prepare for licensing negotiations with robust research.
              </p>
            </div>
            <div className='mb-20'>
              <p className='font-customFont2 !font-semibold !text-xl mb-10'>Lending our expertise to our valued clients across industries</p>
              {/* <p className='mb-10'>At ExpertLancing, we don’t just provide IP data—we provide actionable insights that help businesses and legal teams make licensing decisions with clarity and confidence.</p> */}
              <div className='grid grid-cols-3 grid-rows-2 gap-10'>
                <div className='sep-card w-full h-96 relative overflow-hidden'>
                  <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                    <p className="sep-card-head !font-customFont3 font-semibold !text-[1.7rem] w-10/12">
                    Corporate IP & Legal Teams Managing Patent Portfolios</p>
                    <p className="sep-card-body pt-2 !text-sm opacity-0">For enterprises that own extensive patent portfolios, we provide data-backed insights on which patents hold licensing potential, which assets should be protected, and which carry risk. Our portfolio segmentation and market intelligence ensure that licensing efforts are strategically aligned with business goals.</p>
                    <p className='sep-card-bait !text-xs absolute bottom-5 right-5 cursor-default'>See more</p>
                  </div>
                  <img className='z-20 w-full brightness-50 h-full object-cover' src={`/solution_img/lic-tab2-card2.png`} alt=''/>
                </div>
                <div className='sep-card w-full h-96 relative overflow-hidden'>
                  <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                    <p className="sep-card-head !font-customFont3 font-semibold !text-[1.7rem] w-full">
                    R&D-Driven Companies & Emerging Innovators</p>
                    <p className="sep-card-body pt-2 !text-sm opacity-0">Companies investing heavily in new technology development often need to secure third-party IP rights or license out their innovations. Our research helps R&D teams understand the patent landscape, ensuring their technology development aligns with existing and future IP risks and opportunities.</p>
                    <p className='sep-card-bait !text-xs absolute bottom-5 right-5 cursor-default'>See more</p>
                  </div>
                  <img className='z-20 w-full brightness-50 h-full object-cover' src={`/solution_img/lic-tab2-card1.png`} alt=''/>
                </div>
                <div className='sep-card w-full h-96 relative overflow-hidden'>
                  <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                    <p className="sep-card-head !font-customFont3 font-semibold !text-[1.7rem] w-full">
                    Technology Companies Navigating SEP & FRAND Licensing</p>
                    <p className="sep-card-body pt-2 !text-sm opacity-0">Standard-essential patents (SEPs) can be tricky, whether you're a patent holder aiming to monetize or a company looking to license fairly. We offer services like essentiality and portfolio strength analysis, FRAND benchmarking for fair terms, and finding non-infringing alternatives to reduce SEP dependency.</p>
                    <p className='sep-card-bait !text-xs absolute bottom-5 right-5 cursor-default'>See more</p>
                  </div>
                  <img className='z-20 w-full brightness-50 h-full object-cover' src={`/solution_img/lic-tab2-card3.png`} alt=''/>
                </div>
                <div className='sep-card w-full h-96 relative overflow-hidden'>
                  <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                    <p className="sep-card-head !font-customFont3 font-semibold !text-[1.7rem] w-full">
                    Businesses Expanding Into New Markets & Product Lines</p>
                    <p className="sep-card-body pt-2 !text-sm opacity-0">Companies expanding into new tech areas or markets must assess licensing needs to avoid infringement risks. We offer services like patent-to-product mapping to evaluate risks, competitive IP intelligence to spot licensing gaps and partnerships, and risk assessment reports to prevent legal challenges.</p>
                    <p className='sep-card-bait !text-xs absolute bottom-5 right-5 cursor-default'>See more</p>
                  </div>
                  <img className='z-20 w-full brightness-50 h-full object-cover' src={`/solution_img/lic-tab2-card4.png`} alt=''/>
                </div>
                <div className='sep-card w-full h-96 relative overflow-hidden'>
                  <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                    <p className="sep-card-head !font-customFont3 font-semibold !text-[1.7rem] w-full">
                    Law Firms Handling IP Licensing Disputes & Negotiations</p>
                    <p className="sep-card-body pt-2 !text-sm opacity-0">Licensing agreements often lead to legal complexities and disputes. Our research-driven litigation support ensures law firms have the technical, legal, and business intelligence needed to build stronger cases, whether enforcing a licensing claim or defending against an unreasonable royalty demand.</p>
                    <p className='sep-card-bait !text-xs absolute bottom-5 right-5 cursor-default'>See more</p>
                  </div>
                  <img className='z-20 w-full brightness-50 h-full object-cover' src={`/solution_img/lic-tab2-card5.png`} alt=''/>
                </div>
                <div className='sep-card w-full h-96 relative overflow-hidden'>
                  <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                    <p className="!text-sm pb-2">Article</p>
                    <p className="sep-card-head !font-customFont3 font-semibold !text-[1.5rem] w-full">Monetizing Connected Car Data for Future Revenue Growth</p>
                    <p className="pt-2 !text-sm">The Connected Car Revolution and Its Impact on OEMs</p>
                    <Link to={`/Monetizing-Connected-Car-Data-for-Future-Revenue-Growth`} className='!text-xs !text-white absolute bottom-5 right-5 cursor-pointer'>Read more</Link>
                  </div>
                  <div className='z-20 w-full h-full object-cover bg-[rgb(0,50,170)] bg-[linear-gradient(315deg,_rgba(0,50,170,1)_0%,_rgba(0,24,56,1)_100%)]'></div>
                </div>
              </div>
            </div>
            <div className=''>
              <h2 className='font-customFont3 font-normal text-2xl'>We’re Proud to Work With</h2>
              <Brands />
              <p className='font-customFont2 w-full text-right mb-20'>...and many more!</p>
            </div>
        </div>
    ),
    tab3: (
        <div className='section-details'>
            <div className='flex items-start justify-between gap-10 mb-10'>
              <h2 className='w-1/2 font-customFont3 font-semibold text-3xl mb-10'>Data-driven insights, industry expertise, and a strategic mindset—our licensing support is <span className='!font-customFont3 font-semibold text-3xl text-red-700'>designed for real-world impact.</span></h2>
              <p className='w-1/2'>In licensing, the right information is just as important as the right patents. While many firms focus on executing deals, <span className='font-customFont2 text-red-700'>ExpertLancing empowers decision-makers with the intelligence they need</span> to approach licensing strategically. Our research-driven, litigation-ready, and industry-specific approach ensures that every licensing decision is <span className='font-customFont2 text-red-700'>backed by facts, not assumptions.</span></p>
            </div>
            <div className='mb-20'>
              <p className='font-customFont2 !font-semibold !text-xl mb-10'>What Makes Us <span className='font-customFont2 text-red-700'>Different</span> ?</p>
              <div className='grid grid-cols-2 grid-auto-rows auto-rows-min gap-10'>
                <div className='flex flex-col items-center'>
                  <img className='w-auto h-24' src={`/solution_img/lit-tab3-1.png`} alt=''/>
                  <p className="!font-semibold !text-xl text-center">Research-First,<br/> Not Deal-First</p>
                  <p className="pt-2 text-center">We don’t structure deals—we empower those who do. Our role is to equip patent owners, legal teams, and technology leaders with the intelligence needed to make informed, strategic licensing choices.</p>
                </div>
                <div className='flex flex-col items-center'>
                  <img className='w-auto h-24' src={`/solution_img/lit-tab3-3.png`} alt=''/>
                  <p className="!font-semibold !text-xl text-center">Unmatched Technical <br/>& Industry Expertise</p>
                  <p className="pt-2 text-center">Our team consists of technical specialists, industry analysts, and IP strategists who understand the intersection of technology, business, and patents. Whether it’s semiconductors, telecom, software, pharmaceuticals, or mobility, we provide licensing intelligence that is industry-specific and commercially relevant.</p>
                </div>
                <div className='flex flex-col items-center'>
                  <img className='w-auto h-24' src={`/solution_img/lit-tab3-2.png`} alt=''/>
                  <p className="!font-semibold !text-xl text-center">Precision-Driven Portfolio <br/>& Licensing Analytics</p>
                  <p className="pt-2 text-center">Licensing success is about knowing which patents hold true value. We don’t provide broad, generic portfolio reviews—our analyses focus on:</p>
                  <ul>
                    <li className='list-custom-bullet'>Patent strength and enforceability in licensing scenarios.</li>
                    <li className='list-custom-bullet'>Competitive positioning to identify strategic licensing opportunities.</li>
                    <li className='list-custom-bullet'>Technology and market alignment to connect patents with real-world applications.</li>
                  </ul>
                </div>
                <div className='flex flex-col items-center'>
                  <img className='w-auto h-24' src={`/solution_img/lit-tab3-4.png`} alt=''/>
                  <p className="!font-semibold !text-xl text-center">Litigation-Ready Insights for<br/> Stronger Licensing Positions</p>
                  <p className="pt-2 text-center">Licensing negotiations often involve legal challenges, infringement assertions, or disputes. Our research is designed to stand up to legal scrutiny, ensuring that our clients can:</p>
                  <ul>
                    <li className='list-custom-bullet'>Defend against excessive licensing demands with strong technical counterarguments.</li>
                    <li className='list-custom-bullet'>Establish infringement evidence when enforcing patent rights.</li>
                    <li className='list-custom-bullet'>Assess the validity and strength of licensed patents before entering agreements.</li>
                  </ul>
                </div>
                <div className='flex flex-col items-center'>
                  <img className='w-auto h-24' src={`/solution_img/lit-tab3-3.png`} alt=''/>
                  <p className="!font-semibold !text-xl text-center">Strategic SEP & FRAND Compliance Analysis</p>
                  <p className="pt-2 text-center">For SEP owners and licensees, compliance with FRAND obligations is critical. We provide:</p>
                  <ul>
                    <li className='list-custom-bullet'>Benchmarking of licensing rates to ensure fairness in licensing negotiations.</li>
                    <li className='list-custom-bullet'>Analysis of potential over-declarations in SEP portfolios to challenge unfair licensing terms.</li>
                    <li className='list-custom-bullet'>Identification of alternatives to SEPs to mitigate licensing dependencies.</li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
    ),
};

const handleTabChange = (id) => {
    setActiveTab(id);
};


  return (
    <section className='flex flex-col items-center justify-center'>
      <Helmet>
      <title>Licensing Service Suite</title>
      {/* <meta name='description' content='Our team of experts at ExpertLancing excels in guiding businesses through industry challenges with foresight and precision. From market expansion to technology roadmapping, we craft solutions that fuel innovation and secure leadership.' /> */}
      </Helmet>
      <h1 className='text-[4em] font-customFont3 p-5 w-full'>Licensing Service Suite</h1>
      <div className='bg-red-600 flex items-start justify-between px-10 py-5' id='section-details'>
          <h2 className='text-[2em] font-semibold text-white w-[48%]'>Monetizing Your Innovations with Strategic Licensing</h2>
          <p className='w-[48%] pt-2 text-white font-customFont2 font-light'>At ExpertLancing, we help businesses and inventors unlock the full potential of their patent portfolios with smart, strategic licensing solutions. <br/><br/>Whether you're aiming to generate revenue, break into new markets, or minimize litigation risks, our expert-driven approach ensures you achieve the best results.
          <br/><br/>From assessing potential licensees and validating infringement to analyzing licensing trends and tackling SEP and FRAND complexities, we deliver the right insights at the right time. While we don’t negotiate deals, we empower those who do—with precise, litigation-ready research and technical expertise that make licensing strategies stronger and more effective.
          </p>
          {/* <div className='w-1/2'>
          </div> */}
          
      </div>
      <div className='sticky top-14 bg-white z-50 w-full shadow-sm xs:max-sm:hidden'>
          <ul className='flex items-center justify-center gap-28 w-full py-5'>
              <li><a href='#section-details' className={`${activeTab === 'tab1' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab1')}>What we do</a></li>
              <li><a href='#section-details' className={`${activeTab === 'tab2' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab2')}>Who we serve</a></li>
              <li><a href='#section-details' className={`${activeTab === 'tab3' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab3')}>How we do it differently</a></li>
          </ul>
      </div>
      <div className='hyperlink !p-2 mt-10' id='tabs'>
          {tabContents[activeTab]}
      </div>
      <div className='bg-red-600 flex items-start justify-between gap-10 p-10'>
          <h2 className='text-[2em] font-medium text-white w-1/2'>Transform Your Patents into Profitable Assets</h2>
          <div className='w-1/2 flex flex-col items-start gap-10'>
              <p className='text-white font-customFont2 font-light pt-2'>Looking to license out your patents or secure licensing rights for business expansion?
              <br/><br/>We provide the expertise you need.
              </p>
              <Link className='bg-white text-[#464646] p-2 hover:text-red-700' to={`/contact`}>Contact Us</Link>
          </div>
      </div>

      <hr className='mt-10' id="related" />
      <h3 className='text-[1.5rem] font-customFont3 w-full text-left pl-12 xs:max-sm:text-[1.2em] font-normal my-10'>
          Related Articles
      </h3>
      <div className="slider-container w-11/12">
          {insightsSlider.length > 0 ? (
              <Slider {...settings}>{insightsSlider}</Slider>
          ) : (
              <p>No related articles found for "Licensing".</p>
          )}
      </div>
    </section>
  )
}
