import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import vector from "../assets/Vector.png";
import News from '../components/newsletter';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./IndustryContent.css"
import { Link } from "react-router-dom";
import fetchAndReadExcel from '../components/fetchAndReadExcel';

const IndustryCont = (data) => {
    const [insights, setInsights] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('tab1');
    const [activeNav, setActiveNav] = useState('overview');

        useEffect(() => {
            const getData = async () => {
                try {
                    const jsonData = await fetchAndReadExcel();
                    setInsights(jsonData['data3']);
                } catch (err) {
                    setError(err.message);
                } finally {
                    setLoading(false);
                }
            };
    
            getData();// Set up the Intersection Observer
            
        }, []);

        useEffect(() => {
            const handleScroll = () => {
              const sections = ['overview', 'subsector', 'literature', 'client'];
              let currentSection = '';
        
              sections.forEach((section) => {
                const element = document.getElementById(section);
                if (element) {
                  const rect = element.getBoundingClientRect();
                  if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
                    currentSection = section;
                  }
                }
              });
        
              if (currentSection) {
                setActiveNav(currentSection);
              }
            };
        
            window.addEventListener('scroll', handleScroll);
            
            return () => {
              window.removeEventListener('scroll', handleScroll);
            };
          }, []);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    const industry_data = data.data;
    document.title = `${industry_data.Name}`;
    const iconUrl = `/industry_img/${data.data.icon}`;
    const sub_array = Object.keys(industry_data);
    const Sub_category1 = [];
    for(let i=0;i<sub_array.length;i++){
        if(sub_array[i].includes('Sub_category') && !(sub_array[i].includes('_banner') || sub_array[i].includes('_tagline') || sub_array[i].includes('_overview') || sub_array[i].includes('_infographic')|| sub_array[i].includes('_testimonial')|| sub_array[i].includes('_testimonial_name'))){
            let path = '/'+industry_data[sub_array[i]];
            Sub_category1.push((
                <Link to= {path.replaceAll(' ', '-')} className='w-full'>
                    <div class="flex flex-row items-center justify-center gap-3 subsec border border-red-800 h-16 w-full px-7 relative xs:max-sm:justify-between xs:max-sm:px-5">
                        <img src={iconUrl} alt="card-icon" className="absolute top-2 -left-5 w-10 bg-white p-2 shadow rounded-full xs:max-sm:hidden" />
                        <h2 className="text-sm font-semibold text-red-800">{industry_data[sub_array[i]]}</h2>
                        <img className='w-5' src={`long_arrow_rightR.png`} alt=''/>
                        {/* <p className="text-xs">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam perspiciatis voluptatem vel nemo aspernatur neque debitis.</p> */}
                    </div>
                </Link>
            ))
        }
    };
    const imageUrl = `/industry_img/${data.data.banner}`;
    const tab1ImageUrl = `/industry_img/${data.data.Tab1_image}`;
    const tab2ImageUrl = `/industry_img/${data.data.Tab2_image}`;
    const tab3ImageUrl = `/industry_img/${data.data.Tab3_image}`;
    const tab4ImageUrl = `/industry_img/${data.data.Tab4_image}`;
    const tab5ImageUrl = `/industry_img/${data.data.Tab5_image}`;
    let case_study = [];
    for(let [key, value] of Object.entries(insights)){
        if(industry_data.Name in value && case_study.length<=4){
        case_study.push(value);
        }
    }
    //console.log(case_study)
    const tabContents = {
        tab1: (
            <div className='flex items-start justify-center gap-10 border rounded h-full p-10 my-4 xs:max-sm:flex-col xs:max-sm:w-full xs:max-sm:items-center xs:max-sm:p-7'>
                <img className='h-44 w-5/12 object-cover xs:max-sm:w-full' src={tab1ImageUrl} alt="" />
                <p className='px-5 w-7/12 xs:max-sm:w-full xs:max-sm:px-0 xs:max-sm:text-sm'>
                    {industry_data.Tab1_content}
                </p>
            </div>
        ),
        tab2: (
            <div className='flex items-start justify-center gap-10 border rounded h-full p-10 my-4 xs:max-sm:flex-col xs:max-sm:w-full xs:max-sm:items-center xs:max-sm:p-7'>
                <img className='h-44 w-5/12 object-cover xs:max-sm:w-full' src={tab2ImageUrl} alt="" />
                <p className='px-5 w-7/12 xs:max-sm:w-full xs:max-sm:px-0 xs:max-sm:text-sm'>
                {industry_data.Tab2_content}
                </p>
            </div>
        ),
        tab3: (
            <div className='flex items-start justify-center gap-10 border rounded h-full p-10 my-4 xs:max-sm:flex-col xs:max-sm:w-full xs:max-sm:items-center xs:max-sm:p-7'>
                <img className='h-44 w-5/12 object-cover xs:max-sm:w-full' src={tab3ImageUrl} alt="" />
                <p className='px-5 w-7/12 xs:max-sm:w-full xs:max-sm:px-0 xs:max-sm:text-sm'>
                {industry_data.Tab3_content}
                </p>
            </div>
        ),
        tab4: (
            <div className='flex items-start justify-center gap-10 border rounded h-full p-10 my-4 xs:max-sm:flex-col xs:max-sm:w-full xs:max-sm:items-center xs:max-sm:p-7'>
                <img className='h-44 w-5/12 object-cover xs:max-sm:w-full' src={tab4ImageUrl} alt="" />
                <p className='px-5 w-7/12 xs:max-sm:w-full xs:max-sm:px-0 xs:max-sm:text-sm'>
                {industry_data.Tab4_content}
                </p>
            </div>
        ),
        tab5: (
            <div className='flex items-start justify-center gap-10 border rounded h-full p-10 my-4 xs:max-sm:flex-col xs:max-sm:w-full xs:max-sm:items-center xs:max-sm:p-7'>
                <img className='h-44 w-5/12 object-cover xs:max-sm:w-full' src={tab5ImageUrl} alt="" />
                <p className='px-5 w-7/12 xs:max-sm:w-full xs:max-sm:px-0 xs:max-sm:text-sm'>
                {industry_data.Tab5_content}
                </p>
            </div>
        )
    };

    const case_study_div = [];
    const ccc = [];
    if(case_study.length != 0){
        ccc.push((
            <div className="flex flex-col items-center justify-center gap-5 w-10/12 py-10" id="literature">
                <div className="flex items-center justify-between w-full">
                    <p className="text-2xl font-semibold xs:max-sm:text-[1.2em]">Related Articles</p>
                    <Link to="/insights" class='border rounded py-2 px-5 bg-white text-red-800 font-semibold xs:max-sm:text-sm xs:max-sm:px-3 xs:max-sm:py-1'>Explore</Link>
                </div>
                <div className="w-full max-h-fit grid grid-cols-6 grid-rows-8 gap-4 xs:max-sm:flex xs:max-sm:flex-col xs:max-sm:items-center xs:max-sm:gap-5">
                    {case_study_div}
                </div>
            </div>
        ));
    }
    if(case_study.length == 1){
        case_study_div.push((
            <Link to ={`/${case_study[0].title.replaceAll(' ', '-')}`} className="case-card col-span-4 row-span-4">
                <div class="absolute top-0 p-8 text-white z-30 xs:max-sm:p-4 xs:max-sm:w-full">
                    <h2 class="casecard-title">{case_study[0].title}</h2>
                    <p class="xs:max-sm:truncate xs:max-sm:h-full h-20 overflow-hidden text-sm w-full text-justify">{case_study[0].Para_1}</p>
                </div>
                <Link to={`/${case_study[0].title.replaceAll(' ', '-')}`} className='flex items-center justify-center gap-2 absolute bottom-5 right-5 text-white text-xs z-30'>Read More <img className='w-3' src={`chevron_rw.png`} alt="" /></Link>
                <button type="button" class="absolute top-4 text-white border-white right-4 border rounded p-1 text-xxs z-50 xs:max-sm:bg-white xs:max-sm:font-semibold xs:max-sm:text-gray-500 xs:max-sm:top-[75%] xs:max-sm:right-[73%] xs:max-sm:w-[56px]">{case_study[0].insight_type}</button>
                <div className='absolute case-overlay bg-black opacity-50 h-full w-full z-20'></div>
                <img class='w-full h-full object-cover'  src={`/insights_img/${case_study[0].main_image_all}`} alt=""/>
            </Link>
        ));
    }
    if(case_study.length == 2){
        case_study_div.push((
            <Link to ={`/${case_study[0].title.replaceAll(' ', '-')}`} className="case-card col-span-4 row-span-4">
                <div class="absolute top-0 p-8 text-white z-30 xs:max-sm:p-4 xs:max-sm:w-full">
                    <h2 class="casecard-title">{case_study[0].title}</h2>
                    <p class="xs:max-sm:truncate xs:max-sm:h-full h-20 overflow-hidden text-sm w-full text-justify">{case_study[0].Para_1}</p>
                </div>
                <Link to={`/${case_study[0].title.replaceAll(' ', '-')}`} className='flex items-center justify-center gap-2 absolute bottom-5 right-5 text-white text-xs z-30'>Read More <img className='w-3' src={`chevron_rw.png`} alt="" /></Link>
                <button type="button" class="absolute top-4 text-white border-white right-4 border rounded p-1 text-xxs z-50 xs:max-sm:bg-white xs:max-sm:font-semibold xs:max-sm:text-gray-500 xs:max-sm:top-[75%] xs:max-sm:right-[73%] xs:max-sm:w-[56px]">{case_study[0].insight_type}</button>
                <div className='absolute case-overlay bg-black opacity-50 h-full w-full z-20'></div>
                <img class='w-full h-full object-cover'  src={`/insights_img/${case_study[0].main_image_all}`} alt=""/>
            </Link>
        ));
        case_study_div.push((
            <Link to ={`/${case_study[1].title.replaceAll(' ', '-')}`} className="case-card col-span-2 row-span-4 col-start-5">
                <div class="absolute top-0 p-8 text-white z-30 xs:max-sm:p-4 xs:max-sm:w-full">
                    <h2 class="casecard-title">{case_study[1].title}</h2>
                    <p class="xs:max-sm:truncate xs:max-sm:h-full h-20 overflow-hidden text-sm w-full text-justify">{case_study[1].Para_1}</p>
                </div>
                <Link to={`/${case_study[1].title.replaceAll(' ', '-')}`} className='flex items-center justify-center gap-2 absolute bottom-5 right-5 text-white text-xs z-30'>Read More <img className='w-3' src={`chevron_rw.png`} alt="" /></Link>
                <button type="button" class="absolute top-4 text-white border-white right-4 border rounded p-1 text-xxs z-50 xs:max-sm:bg-white xs:max-sm:font-semibold xs:max-sm:text-gray-500 xs:max-sm:top-[75%] xs:max-sm:right-[73%] xs:max-sm:w-[56px]">{case_study[1].insight_type}</button>
                <div className='absolute case-overlay bg-black opacity-50 h-full w-full z-20'></div>
                <img class='w-full h-full object-cover' src={`/insights_img/${case_study[1].main_image_all}`} alt=""/>
            </Link>
        ));
    }
    if(case_study.length == 3){
        case_study_div.push((
            <Link to ={`/${case_study[0].title.replaceAll(' ', '-')}`} className="case-card col-span-4 row-span-4">
                <div class="absolute top-0 p-8 text-white z-30 xs:max-sm:p-4 xs:max-sm:w-full">
                    <h2 class="casecard-title">{case_study[0].title}</h2>
                    <p class="xs:max-sm:truncate xs:max-sm:h-full h-20 overflow-hidden text-sm w-full text-justify">{case_study[0].Para_1}</p>
                </div>
                <Link to={`/${case_study[0].title.replaceAll(' ', '-')}`} className='flex items-center justify-center gap-2 absolute bottom-5 right-5 text-white text-xs z-30'>Read More <img className='w-3' src={`chevron_rw.png`} alt="" /></Link>
                <button type="button" class="absolute top-4 text-white border-white right-4 border rounded p-1 text-xxs z-50 xs:max-sm:bg-white xs:max-sm:font-semibold xs:max-sm:text-gray-500 xs:max-sm:top-[75%] xs:max-sm:right-[73%] xs:max-sm:w-[56px]">{case_study[0].insight_type}</button>
                <div className='absolute case-overlay bg-black opacity-50 h-full w-full z-20'></div>
                <img class='w-full h-full object-cover'  src={`/insights_img/${case_study[0].main_image_all}`} alt=""/>
            </Link>
        ));
        case_study_div.push((
            <Link to ={`/${case_study[1].title.replaceAll(' ', '-')}`} className="case-card col-span-2 row-span-4 col-start-5">
                <div class="absolute top-0 p-8 text-white z-30 xs:max-sm:p-4 xs:max-sm:w-full">
                    <h2 class="casecard-title">{case_study[1].title}</h2>
                    <p class="xs:max-sm:truncate xs:max-sm:h-full h-20 overflow-hidden text-sm w-full text-justify">{case_study[1].Para_1}</p>
                </div>
                <Link to={`/${case_study[1].title.replaceAll(' ', '-')}`} className='flex items-center justify-center gap-2 absolute bottom-5 right-5 text-white text-xs z-30'>Read More <img className='w-3' src={`chevron_rw.png`} alt="" /></Link>
                <button type="button" class="absolute top-4 text-white border-white right-4 border rounded p-1 text-xxs z-50 xs:max-sm:bg-white xs:max-sm:font-semibold xs:max-sm:text-gray-500 xs:max-sm:top-[75%] xs:max-sm:right-[73%] xs:max-sm:w-[56px]">{case_study[1].insight_type}</button>
                <div className='absolute case-overlay bg-black opacity-50 h-full w-full z-20'></div>
                <img class='w-full h-full object-cover' src={`/insights_img/${case_study[1].main_image_all}`} alt=""/>
            </Link>
        ));
        case_study_div.push((
            <Link to ={`/${case_study[2].title.replaceAll(' ', '-')}`} className="case-card col-span-3 row-span-4 row-start-5">
                <div class="absolute top-0 p-8 text-white z-30 xs:max-sm:p-4 xs:max-sm:w-full">
                    <h2 class="casecard-title">{case_study[2].title}</h2>
                    <p class="xs:max-sm:truncate xs:max-sm:h-full h-20 overflow-hidden text-sm w-full text-justify">{case_study[2].Para_1}</p>
                </div>
                <Link to={`/${case_study[2].title.replaceAll(' ', '-')}`} className='flex items-center justify-center gap-2 absolute bottom-5 right-5 text-white text-xs z-30'>Read More <img className='w-3' src={`chevron_rw.png`} alt="" /></Link>
                <button type="button" class="absolute top-4 text-white border-white right-4 border rounded p-1 text-xxs z-50 xs:max-sm:bg-white xs:max-sm:font-semibold xs:max-sm:text-gray-500 xs:max-sm:top-[75%] xs:max-sm:right-[73%] xs:max-sm:w-[56px]">{case_study[2].insight_type}</button>
                <div className='absolute case-overlay bg-black opacity-50 h-full w-full z-20'></div>
                <img class='w-full h-full object-cover'  src={`/insights_img/${case_study[2].main_image_all}`} alt=""/>
            </Link>
        ));
    }
    if(case_study.length >= 4){
        case_study_div.push((
            <Link to ={`/${case_study[0].title.replaceAll(' ', '-')}`} className="case-card col-span-4 row-span-4">
                <div class="absolute top-0 p-8 text-white z-30 xs:max-sm:p-4 xs:max-sm:w-full">
                    <h2 class="casecard-title">{case_study[0].title}</h2>
                    <p class="xs:max-sm:truncate xs:max-sm:h-full h-20 overflow-hidden text-sm w-full text-justify">{case_study[0].Para_1}</p>
                </div>
                <Link to={`/${case_study[0].title.replaceAll(' ', '-')}`} className='flex items-center justify-center gap-2 absolute bottom-5 right-5 text-white text-xs z-30'>Read More <img className='w-3' src={`chevron_rw.png`} alt="" /></Link>
                <button type="button" class="absolute top-4 text-white border-white right-4 border rounded p-1 text-xxs z-50 xs:max-sm:bg-white xs:max-sm:font-semibold xs:max-sm:text-gray-500 xs:max-sm:top-[75%] xs:max-sm:right-[73%] xs:max-sm:w-[56px]">{case_study[0].insight_type}</button>
                <div className='absolute case-overlay bg-black opacity-50 h-full w-full z-20'></div>
                <img class='w-full h-full object-cover'  src={`/insights_img/${case_study[0].main_image_all}`} alt=""/>
            </Link>
        ));
        case_study_div.push((
            <Link to ={`/${case_study[1].title.replaceAll(' ', '-')}`} className="case-card col-span-2 row-span-4 col-start-5">
                <div class="absolute top-0 p-8 text-white z-30 xs:max-sm:p-4 xs:max-sm:w-full">
                    <h2 class="casecard-title">{case_study[1].title}</h2>
                    <p class="xs:max-sm:truncate xs:max-sm:h-full h-20 overflow-hidden text-sm w-full text-justify">{case_study[1].Para_1}</p>
                </div>
                <Link to={`/${case_study[1].title.replaceAll(' ', '-').replaceAll(' ', '-')}`} className='flex items-center justify-center gap-2 absolute bottom-5 right-5 text-white text-xs z-30'>Read More <img className='w-3' src={`chevron_rw.png`} alt="" /></Link>
                <button type="button" class="absolute top-4 text-white border-white right-4 border rounded p-1 text-xxs z-50 xs:max-sm:bg-white xs:max-sm:font-semibold xs:max-sm:text-gray-500 xs:max-sm:top-[75%] xs:max-sm:right-[73%] xs:max-sm:w-[56px]">{case_study[1].insight_type}</button>
                <div className='absolute case-overlay bg-black opacity-50 h-full w-full z-20'></div>
                <img class='w-full h-full object-cover' src={`/insights_img/${case_study[1].main_image_all}`} alt=""/>
            </Link>
        ));
        case_study_div.push((
            <Link to ={`/${case_study[2].title.replaceAll(' ', '-').replaceAll(' ', '-')}`} className="case-card col-span-3 row-span-4 row-start-5">
                <div class="absolute top-0 p-8 text-white z-30 xs:max-sm:p-4 xs:max-sm:w-full">
                    <h2 class="casecard-title">{case_study[2].title}</h2>
                    <p class="xs:max-sm:truncate xs:max-sm:h-full h-20 overflow-hidden text-sm w-full text-justify">{case_study[2].Para_1}</p>
                </div>
                <Link to={`/${case_study[2].title.replaceAll(' ', '-').replaceAll(' ', '-')}`} className='flex items-center justify-center gap-2 absolute bottom-5 right-5 text-white text-xs z-30'>Read More <img className='w-3' src={`chevron_rw.png`} alt="" /></Link>
                <button type="button" class="absolute top-4 text-white border-white right-4 border rounded p-1 text-xxs z-50 xs:max-sm:bg-white xs:max-sm:font-semibold xs:max-sm:text-gray-500 xs:max-sm:top-[75%] xs:max-sm:right-[73%] xs:max-sm:w-[56px]">{case_study[2].insight_type}</button>
                <div className='absolute case-overlay bg-black opacity-50 h-full w-full z-20'></div>
                <img class='w-full h-full object-cover'  src={`/insights_img/${case_study[2].main_image_all}`} alt=""/>
            </Link>
        ));
        case_study_div.push(
            <Link to ={`/${case_study[3].title.replaceAll(' ', '-')}`} className="case-card col-span-3 row-span-4 row-start-5">
                <div class="absolute top-0 p-8 text-white z-30 xs:max-sm:p-4 xs:max-sm:w-full">
                    <h2 class="casecard-title">{case_study[3].title}</h2>
                    <p class="xs:max-sm:truncate xs:max-sm:h-full h-20 overflow-hidden text-sm w-full text-justify">{case_study[3].Para_1}</p>
                </div>
                <Link to={`/${case_study[3].title.replaceAll(' ', '-')}`} className='flex items-center justify-center gap-2 absolute bottom-5 right-5 text-white text-xs z-30'>Read More <img className='w-3' src={`chevron_rw.png`} alt="" /></Link>
                <button type="button" class="absolute top-4 text-white border-white right-4 border rounded p-1 text-xxs z-50 xs:max-sm:bg-white xs:max-sm:font-semibold xs:max-sm:text-gray-500 xs:max-sm:top-[75%] xs:max-sm:right-[73%] xs:max-sm:w-[56px]">{case_study[3].insight_type}</button>
                <div className='absolute case-overlay bg-black opacity-50 h-full w-full z-20'></div>
                <img class='w-full h-full object-cover'  src={`/insights_img/${case_study[3].main_image_all}`} alt=""/>
            </Link>
        );
    }
    
    const handleTabChange = (id) => {
        setActiveTab(id);
    };

    
    const handleNavClick = (id) => {
        setActiveNav(id);
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };


    const settings = {
        // dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        speed: 800,
        cssEase: "linear"
    };

     


    return (
        <section className='flex flex-col items-center justify-center'>
            <Helmet>
            <title>Chemicals</title>
            <meta name='description' content='Driving innovation with cutting-edge chemical solutions while navigating the dynamic market landscape and pioneering the future of sustainable chemical technologies.This is Homepage' />
            </Helmet>

            <div class='ind-banner overflow-hidden h-[80vh] w-full relative'>
                <img class='h-full w-full object-cover brightness-50' src={imageUrl} alt="" />
                <div class="absolute top-0 flex items-start justify-between h-full w-full xs:max-sm:flex-col">
                    <div className='flex flex-col w-8/12 justify-center items-start p-8 xs:max-sm:w-full xs:max-sm:h-fit'>
                        <div className="text-[4em] w-full text-[white] uppercase xs:max-sm:text-[2em]">
                            <h2 className='font-customFont3'>{industry_data.Name}</h2>
                        </div>
                        <div className="text-[16px] font-light xs:max-sm:text-[14px] text-white">
                            <p className='font-customFont2'>{industry_data.Tagline}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sticky top-14 bg-white z-50 w-full shadow-sm xs:max-sm:hidden'>
                <ul class='in-nav-active innernav flex items-center justify-center gap-28 w-full py-5 xs:max-sm:gap-0 xs:max-sm:text-sm xs:max-sm:justify-around'>
                    <a href="#overview"><li className={activeNav === 'overview' ? 'active-inner' : ''} onClick={() => handleNavClick('overview')}>Overview</li></a>
                    <a href="#subsector"><li className={activeNav === 'subsector' ? 'active-inner' : ''} onClick={() => handleNavClick('subsector')}>Sub-Sectors</li></a>
                    <a href="#literature"><li className={activeNav === 'literature' ? 'active-inner' : ''} onClick={() => handleNavClick('literature')}>Insights</li></a>
                    <a href="#client"><li className={activeNav === 'client' ? 'active-inner' : ''} onClick={() => handleNavClick('client')}>Client Success</li></a>
                    {/* <a href="#faq"><li className={activeNav === 'faq' ? 'active-inner' : ''} onClick={() => handleNavClick('faq')}>FAQ</li></a> */}
                </ul>
            </div>
            <div class='flex flex-col items-center' id="overview">
                <p class='w-10/12 py-10 text-justify xs:max-sm:w-screen xs:max-sm:text-sm xs:max-sm:px-10'>
                    {industry_data.Overview}
                </p>

                <div className='w-11/12 xs:max-sm:flex xs:max-sm:flex-col xs:max-sm:items-center'>
                    {/* Tabs */}
                    <div className='tab-heads xs:max-sm:hidden flex items-center justify-center gap-12 xs:max-sm:gap-3 xs:max-sm:flex-col xs:max-sm:w-10/12'>
                        <button className={`xs:max-sm:w-full ${activeTab === 'tab1' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab1')}>
                            {industry_data.Tab1_header}
                        </button>
                        <button className={`xs:max-sm:w-full ${activeTab === 'tab2' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab2')}>
                            {industry_data.Tab2_header}
                        </button>
                        <button className={`xs:max-sm:w-full ${activeTab === 'tab3' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab3')}>
                        {industry_data.Tab3_header}
                        </button>
                        <button className={`xs:max-sm:w-full ${activeTab === 'tab4' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab4')}>
                        {industry_data.Tab4_header}
                        </button>
                        <button className={`xs:max-sm:w-full ${activeTab === 'tab5' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab5')}>
                        {industry_data.Tab5_header}
                        </button>
                    </div>

                    <div className='tab-heads-resp hidden  xs:max-sm:flex items-center justify-center gap-12 xs:max-sm:gap-3 xs:max-sm:flex-col xs:max-sm:w-10/12'>
                        <a href='#tabs' className={`xs:max-sm:w-full ${activeTab === 'tab1' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab1')}>
                            {industry_data.Tab1_header}
                        </a>
                        <a href='#tabs' className={`xs:max-sm:w-full ${activeTab === 'tab2' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab2')}>
                            {industry_data.Tab2_header}
                        </a>
                        <a href='#tabs' className={`xs:max-sm:w-full ${activeTab === 'tab3' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab3')}>
                        {industry_data.Tab3_header}
                        </a>
                        <a href='#tabs' className={`xs:max-sm:w-full ${activeTab === 'tab4' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab4')}>
                        {industry_data.Tab4_header}
                        </a>
                        <a href='#tabs' className={`xs:max-sm:w-full ${activeTab === 'tab5' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab5')}>
                        {industry_data.Tab5_header}
                        </a>
                    </div>

                    {/* Content area */}
                    <div className='xs:max-sm:w-10/12' id='tabs'>
                        {tabContents[activeTab]}
                    </div>
                </div>

                {/* <IndustryTab /> */}
            </div>
            <div id="subsector" className='w-10/12 py-16 xs:max-sm:flex xs:max-sm:flex-col xs:max-sm:items-center'>
                <h2 className='text-2xl font-semibold pb-5 xs:max-sm:text-[1.2em] xs:max-sm:w-full'>Sub-Sectors</h2>
                <div className="grid grid-cols-4 auto-rows-auto gap-10 w-full xs:max-sm:grid-cols-1 xs:max-sm:gap-5 xs:max-sm:w-10/12" >
                    {Sub_category1}
                </div>
            </div>
            {ccc}
            <div id="client" className="testimonies relative w-7/12 h-40 my-10 xs:max-sm:w-10/12">
                <h2 className="text-xl font-semibold text-center xs:max-sm:text-[1.2em]">Actions Speak Louder Than Words</h2>
                <img className="absolute bottom-0 right-0" src={vector} alt="" />
                <Slider {...settings} className='py-10'>
                    <div className='!flex flex-col items-start'>
                        <p className="text-sm">{industry_data.Main_testimonial1}</p>
                        <span className="w-full text-xs text-red-800">~{industry_data.Main_testimonial_name1}</span>
                    </div>
                    <div className='!flex flex-col items-start'>
                        <p className="text-sm">{industry_data.Main_testimonial2}</p>
                        <span className="w-full text-xs text-red-800">~{industry_data.Main_testimonial_name2}</span>
                    </div>
                    <div className='!flex flex-col items-start'>
                        <p className="text-sm">{industry_data.Main_testimonial3}</p>
                        <span className="w-full text-xs text-red-800">~{industry_data.Main_testimonial_name3}</span>
                    </div>
                </Slider>
            </div>
            {/* <Faq /> */}
            <News />
        </section>
    )
}

export default IndustryCont