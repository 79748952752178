import React, { useState, useEffect } from "react";
import './Insight-casestudy.css';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useMediaQuery from '../components/useMediaQuery';
import fetchAndReadExcel from '../components/fetchAndReadExcel';
import { format } from 'date-fns';

import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterShareButton,
    XIcon,
} from 'react-share';


const InsightCasestudy = ({ data }) => {
    const [insights, setInsights] = useState([]);
    const [insights_ccc, setinsights_ccc] = useState({});
    const [images, setImages] = useState([]);
    const insightsData = data;
    document.title = `${insightsData.title}`;
    const shareUrl = `${"expertlancing.com/" + insightsData.title.replaceAll(' ', '-')}`;
    const insighttitle = `${insightsData.title}`;
    const isSmallScreen = useMediaQuery('(max-width: 600px)');

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "50px",
        slidesToShow: isSmallScreen ? 1 : 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        cssEase: "linear"
    };

    useEffect(() => {
        const getData = async () => {
            const jsonData = await fetchAndReadExcel();
            let data = jsonData['data3'];

            // Sort the data by date (assuming the date field is named 'date' and is in ISO format)
            data = data.sort((a, b) => new Date(b.Submitted_on) - new Date(a.Submitted_on));

            setInsights(data);
        };
        getData();
    }, []);

        useEffect(() => {
            const processedData = {};
            const imageMap = {};
            for (let i = 1; i <= insightsData.NoofParagraphs; i++) {
                let headData = '', paraData = '';
                if (insightsData[`head_${i}`]) {
                    headData = insightsData[`head_${i}`];
                }
                if (insightsData[`Para_${i}`]) {
                    paraData = insightsData[`Para_${i}`];
                }
                if (insightsData[`image_${i}`]) {
                    imageMap[insightsData[`image_${i}_loc`]] = insightsData[`image_${i}`];
                }
                processedData[i] = [headData, paraData];
            }
            setinsights_ccc(processedData);
            setImages(imageMap);
        }, [insightsData]);

        const industry_name = ['Chemicals', 'Food and Nutrition', 'Energy and Natural Resources', 'Home and Personal Care', 'Healthcare and Life Sciences', 'Mobility', 'Industrials', 'Technology Media and Telecom', 'General', 'AI & Automation', 'Portfolio Licensing & Monetization', 'Standards & SEPs', 'Technology Scouting & Open Innovation', 'Innovation & R&D Strategy', 'Competitor Intelligence', 'Sustainability & Green Tech', 'Client Success Stories', 'Startup Analysis', 'Strategic Market Insights', 'IP Strategy and Patent Intelligence', 'Growth Strategy', 'Technology Landscape', 'Trends in Patent Litigation']
            const selected_name = [];
            for (let i = 0; i < industry_name.length; i++) {
                if (Object.keys(insightsData).includes(industry_name[i])) {
                    selected_name.push(industry_name[i]);
                }
            }
            const insightslider = []
            for (let i = 0; i < insights.length; i++) {
                for (let j = 0; j < selected_name.length; j++) {
                    if (insights[i][selected_name[j]] != undefined && insights[i][selected_name[j]] == 'y' && insights[i]['title'] != insighttitle) {
                        insightslider.push((
                            <Link className="cardcont" to={`/${insights[i].title.replaceAll(' ', '-')}`}>
                                <div className='slidecard'>
                                    <img src={`/insights_img/${insights[i].main_image_all}`} alt={insights[i].title} />
                                    <div className="slidecard-txt h-full">
                                        <div>
                                            <span className="slidecard-tag">{insights[i].insight_type}</span>&nbsp;&nbsp;&nbsp;
                                            <span className="slidecard-tag">
                                                {format(new Date(ExcelDateToJSDate(insightsData.Submitted_on).toDateString()), 'EEEE, MMM, d, yyyy')}
                                            </span>
                                        </div>
                                        <h3>{insights[i].title}</h3>
                                        <p className="truncate" dangerouslySetInnerHTML={{ __html: insights[i].Abstract }}></p>
                                        {/* <Link to={`/${insights[i].title.replaceAll(' ', '-')}`} className='absolute bottom-5 right-5 font-semibold  w-full text-right hover:text-red-700 text-xs z-30'>
                                            Read More
                                        </Link> */}
                                    </div>
                                </div>
                            </Link>
                        ))
                        break;
                    }
                }
            }

        for (let [key, value] of Object.entries(images)) {
            const index = key.slice(0, -1);
            if (insights_ccc[index]) {
                insights_ccc[index].push(value);
                insights_ccc[index].push(key.slice(-1));
            }
        }
    
        const sub_data = [];
        for (let [key, value] of Object.entries(insights_ccc)) {
            const iconUrl = `/insights_img/${value[2]}`;
            const imgClass = `head-img-${value[3]}`;
            sub_data.push((
                <div className="xs:max-sm:w-10/12 xs:max-sm:m-auto">
                    <h3 className="font-customFont4 text-[1.5rem] mb-[5px]" dangerouslySetInnerHTML={{ __html: value[0] }}></h3>
                    <div className="w-10/12 xs:max-sm:w-full mb-[12px]">
                        {value[2] && (
                            <img src={iconUrl} alt="" className={imgClass} />
                        )}
                        <p className="font-customFont2 font-light text-[16px] py-2 text-justify whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: value[1] }}></p>
                    </div>
                </div>
            ));
        }
    


    const imagePath = `/insights_img/${insightsData.main_image_all}`;

    function ExcelDateToJSDate(serial) {
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400 * 1000; // Convert to milliseconds
        const date_info = new Date(utc_value);
    
        const fractional_day = serial - Math.floor(serial) + 0.0000001;
        let total_seconds = Math.floor(86400 * fractional_day);
        const seconds = total_seconds % 60;
        total_seconds -= seconds;
        const hours = Math.floor(total_seconds / (60 * 60));
        const minutes = Math.floor(total_seconds / 60) % 60;
    
        // Create a new date with valid values
        const year = date_info.getFullYear();
        const month = date_info.getMonth();
        const day = date_info.getDate();
    
        // Ensure the values are valid
        if (isNaN(year) || isNaN(month) || isNaN(day) || 
            isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            return null; // or handle error as appropriate
        }
    
        return new Date(year, month, day, hours, minutes, seconds);
        
    }

    // Convert and format the date
    // let articleDate = ExcelDateToJSDate(insightsData.Submitted_on);
    // let formattedDate = format(new Date(articleDate.toLocaleDateString()), 'MMMM, dd, yyyy');

    const handleCopyLink = () => {
        navigator.clipboard.writeText(shareUrl)
            .then(() => {
                alert("Link copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
    };

    return (
        <section className="flex flex-col items-center overflow-hidden">

            <div className='h-[80vh] w-full relative'>
                <div className="absolute left-0 top-0 p-8 z-20 flex flex-col gap-3 h-auto w-full">
                    <h1 className="text-white text-[3em] font-customFont3 font-normal xs:max-sm:text-[2em]">{insightsData.title}</h1>
                    <h2 className="font-customFont2 font-light text-white text-[16px] italic text-left w-9/12 xs:max-sm:w-full">{insightsData.Abstract}</h2>
                    <div className="flex items-center gap-5">
                        <p className='text-white text-xs xs:max-sm:text-[14px] border border-white py-1 px-2 rounded w-fit'>{insightsData.insight_type}</p>
                        {/* {expert_pick} */}
                    </div>
                </div>
                <div className="absolute bottom-5 left-10 z-10 flex items-center justify-between w-[93%] xs:max-sm:flex-col-reverse xs:max-sm:items-start xs:max-sm:gap-5 xs:max-sm:left-5 xs:max-sm:w-full">
                    <div className="flex flex-row gap-8 xs:max-sm:gap-0">
                        <div className="flex flex-col border-solid border-gray-100 border-l-2 px-2 w-2/6 xs:max-sm:gap-2">
                            <p className="text-white text-xs">Written by</p>
                            <p className="flex items-center text-sm"><span className="text-white text-[14px] xs:max-sm:text--xs">{insightsData.Prepared_By}</span>
                            </p>
                        </div>

                        <div className="flex flex-col border-solid border-gray-100 border-l-2 px-2 w-2/6 xs:max-sm:gap-2">
                            <p className="text-white text-xs">Published on</p>
                            <p className="flex items-center text-sm"><span className="text-white text-[14px] xs:max-sm:text-xs]"> {format(new Date(ExcelDateToJSDate(insightsData.Submitted_on).toDateString()), 'EEEE, MMM, d, yyyy')} </span>
                            </p>
                        </div>

                        <div className="flex flex-col border-solid border-gray-100 border-l-2 px-2 w-2/6 xs:max-sm:gap-2">
                            <p className="text-white text-xs">Reading Time</p>
                            <p className="flex items-center text-sm"><span className="text-white text-[14px] xs:max-sm:text--xs">{insightsData.Read_time} Minutes</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row gap-4 z-10">
                        <div className="Demo__some-network">
                            <FacebookShareButton
                                url={shareUrl} 
                                className="Demo__some-network__share-button">
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                        </div>
                        <div className="Demo__some-network">
                            <TwitterShareButton
                                url={shareUrl}
                                title={insighttitle}
                                className="Demo__some-network__share-button"
                            >
                                <XIcon size={32} round />
                            </TwitterShareButton>
                        </div>

                        <div className="Demo__some-network">
                            <LinkedinShareButton
                                url={shareUrl}
                                title={insighttitle} 
                                className="Demo__some-network__share-button">
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                        </div>

                        {/* New Copy Link Button */}
                        <div className="Demo__some-network">
                            <button
                                onClick={handleCopyLink}
                                className="Demo__some-network__share-button  bg-gray-500 p-2 rounded-full"
                            >
                                <img className="w-4" src={`insight_copy.png`} alt="" />
                            </button>
                        </div>

                    </div>
                </div>
                <img className='brightness-50 h-full w-full object-cover' src={ imagePath } alt="Insight Banner" />
            </div>

            <div className='w-[75%] flex items-center justify-start flex-wrap gap-5 my-5 xs:max-sm:w-[85%]'>
            {Object.keys(insightsData).filter(key => key.startsWith('Industry')).map((key, index) => (
                <a className='bg-gray-100 text-[#464646] px-[0.7rem] py-[0.3rem] rounded-3xl font-customFont2 text-sm hover:bg-red-700 hover:text-white' href={`#related`} key={index}>{insightsData[key]}</a>
            ))}
            </div>

            <div className="hyperlink xs:max-sm:!w-10/12">
                <h3 className="!text-[1.75rem]">Key Takeaways</h3>
                <ul className="flex flex-col gap-5">
                    {Object.keys(insightsData).filter(key => key.startsWith('Key_Takeaways')).map((key, index) => (
                        <li className="list-custom-bullet font-customFont2 font-light text-[16px] leading-6" key={index} dangerouslySetInnerHTML={{ __html: insightsData[key] }}></li>
                    ))}
                </ul>
            </div>

            <div className="hyperlink xs:max-sm:!w-10/12">
                <h3>Client Challenge</h3>
                <p>{insightsData.Client_Challenge}</p>
                <div className="border-t border-b border-red-800 flex py-16 xs:max-sm:block">
                    <div className="flex flex-col items-start gap-5 border-t-4 border-red-800 p-8 bg-gray-100 text-[#7b7b7b]">
                        <h3 className="!font-customFont5 !font-semibold !text-lg">Objectives</h3>
                        <ul className="list-disc ml-4 flex flex-col items-start gap-2" dangerouslySetInnerHTML={{ __html: insightsData.Objective }}>
                        </ul>
                    </div>
                    <div className="flex flex-col items-start gap-5 border-t-4 border-gray-100 p-8 bg-[#1e1e1e] text-white">
                        <h3 className="!font-customFont5 !font-semibold !text-lg">Key Client Questions</h3>
                        <ul className="list-disc ml-4 flex flex-col items-start gap-2" dangerouslySetInnerHTML={{ __html: insightsData.Key_Client_Ques }}>

                        </ul>
                    </div>
                </div>
            </div>

            <div className="hyperlink xs:max-sm:!w-10/12">
                <h3>{insightsData.Methodology_head}</h3>
                <p dangerouslySetInnerHTML={{ __html: insightsData.Methodology_appr_frame_para1 }}></p>

                <div className="flex items-start gap-10 w-full xs:max-sm:block">
                    <div className="w-5/12 xs:max-sm:w-full">
                        <img className="w-10 opacity-20" src={`/insights_img/casestudy-quotes.png`} alt="" />
                        <h3 className="!text-lg" dangerouslySetInnerHTML={{ __html: insightsData.Methodology_Quote }}></h3>
                    </div>
                    <div className="w-7/12 xs:max-sm:w-full xs:max-sm:pt-5">
                        <p dangerouslySetInnerHTML={{ __html: insightsData.Methodology_appr_frame_para2 }}></p>
                    </div>
                </div>
                <div>
                    <p dangerouslySetInnerHTML={{ __html: insightsData.Methodology_appr_frame_para3 }}></p>
                </div>
                <div>
                    <p dangerouslySetInnerHTML={{ __html: insightsData.Methodology_appr_frame_para4 }}></p>
                </div>
            </div>

            <div className="ml-40 mt-[34px] xs:max-sm:ml-0 xs:max-sm:w-full">
            {insightsData.NoofAnalytics > 0 && (

                <div className="float-right clear-both bg-red-800 px-10 ml-10 mb-10 w-4/12  xs:max-sm:hidden">
                    <div className="my-10 text-white flex flex-col items-center">
                        <h3 className="mb-10 font-customFont3 font-normal text-2xl w-full text-white">{ insightsData.Analytics_title1 }</h3>
                        <p dangerouslySetInnerHTML={{ __html: insightsData.Analytics_data1_para }}></p>
                        <img className="w-9/12" src={`/insights_img/${insightsData.Analytics_data1_img}`} alt="" />
                    </div>

                    <div className="my-10 text-white flex flex-col items-center">
                        <h3 className="mb-10 font-customFont3 font-normal text-2xl w-full text-white">{ insightsData.Analytics_title2 }</h3>
                        <p dangerouslySetInnerHTML={{ __html: insightsData.Analytics_data2_para }}></p>
                        <img className="w-9/12" src={`/insights_img/${insightsData.Analytics_data2_img}`} alt="" />
                    </div>

                    <div className="my-10 text-white flex flex-col items-center">
                        <h3 className="mb-10 font-customFont3 font-normal text-2xl w-full text-white">{ insightsData.Analytics_title3 }</h3>
                        <p dangerouslySetInnerHTML={{ __html: insightsData.Analytics_data3_para }}></p>
                        <img className="w-9/12" src={`/insights_img/${insightsData.Analytics_data3_img}`} alt="" />
                    </div>

                    <div className="my-10 text-white flex flex-col items-center">
                        <h3 className="mb-10 font-customFont3 font-normal text-2xl w-full text-white">{ insightsData.Analytics_title4 }</h3>
                        <p dangerouslySetInnerHTML={{ __html: insightsData.Analytics_data4_para }}></p>
                        <img className="w-9/12" src={`/insights_img/${insightsData.Analytics_data4_img}`} alt="" />
                    </div>

                    <div className="my-10 text-white flex flex-col items-center">
                        <h3 className="mb-10 font-customFont3 font-normal text-2xl w-full text-white">{ insightsData.Analytics_title5 }</h3>
                        <p dangerouslySetInnerHTML={{ __html: insightsData.Analytics_data5_para }}></p>
                        <img className="w-9/12" src={`/insights_img/${insightsData.Analytics_data5_img}`} alt="" />
                    </div>
                    
                </div>
            )}
            {insightsData.NoofAnalytics > 0 && (

                <div className="hidden bg-red-800 p-5 mb-10 w-full xs:max-sm:block">
                    <div className="my-10 text-white flex flex-col items-center">
                        <h3 className="mb-10 font-customFont3 font-normal text-2xl w-full text-white">{ insightsData.Analytics_title1 }</h3>
                        <p dangerouslySetInnerHTML={{ __html: insightsData.Analytics_data1_para }}></p>
                        <img className="w-9/12" src={`/insights_img/${insightsData.Analytics_data1_img}`} alt="" />
                    </div>

                    <div className="my-10 text-white flex flex-col items-center">
                        <h3 className="mb-10 font-customFont3 font-normal text-2xl w-full text-white">{ insightsData.Analytics_title2 }</h3>
                        <p dangerouslySetInnerHTML={{ __html: insightsData.Analytics_data2_para }}></p>
                        <img className="w-9/12" src={`/insights_img/${insightsData.Analytics_data2_img}`} alt="" />
                    </div>

                    <div className="my-10 text-white flex flex-col items-center">
                        <h3 className="mb-10 font-customFont3 font-normal text-2xl w-full text-white">{ insightsData.Analytics_title3 }</h3>
                        <p dangerouslySetInnerHTML={{ __html: insightsData.Analytics_data3_para }}></p>
                        <img className="w-9/12" src={`/insights_img/${insightsData.Analytics_data3_img}`} alt="" />
                    </div>

                    <div className="my-10 text-white flex flex-col items-center">
                        <h3 className="mb-10 font-customFont3 font-normal text-2xl w-full text-white">{ insightsData.Analytics_title4 }</h3>
                        <p dangerouslySetInnerHTML={{ __html: insightsData.Analytics_data4_para }}></p>
                        <img className="w-9/12" src={`/insights_img/${insightsData.Analytics_data4_img}`} alt="" />
                    </div>

                    <div className="my-10 text-white flex flex-col items-center">
                        <h3 className="mb-10 font-customFont3 font-normal text-2xl w-full text-white">{ insightsData.Analytics_title5 }</h3>
                        <p dangerouslySetInnerHTML={{ __html: insightsData.Analytics_data5_para }}></p>
                        <img className="w-9/12" src={`/insights_img/${insightsData.Analytics_data5_img}`} alt="" />
                    </div>
                    
                </div>
            )}
                {sub_data}
            </div>
            <hr id="related" />
            <h3 className='text-[1.5rem] font-customFont3 w-full text-left pl-12 xs:max-sm:text-[1.2em] font-normal my-10'>
                Related Articles
            </h3>
            <div className="slider-container mb-10 w-11/12 h-full xs:max-sm:w-screen">
                <Slider {...settings}>
                    {insightslider}
                </Slider>
            </div>
        </section>
    );
}

export default InsightCasestudy;